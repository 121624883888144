export default defineComponent({
  name: 'DocumentListItemMetaInfoAuthors',
  props: {
    disabled: {
      type: Boolean,
      required: true
    },
    authors: {
      type: Array,
      required: true
    },
    authorsRendered: {
      type: Array,
      required: true
    },
    variant: {
      type: [String, undefined],
      required: false,
      default: undefined
    }
  },
  emits: ['filterByIndex']
});