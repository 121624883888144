<template>
  <v-dialog max-width="1024px" :value="show" @input="$emit('update:show', $event)">
    <v-card id="fullver-introduction-dialog">
      <div class="readme-title">当機能は正式版(有料)でのご提供となります</div>
      <div class="fullver-introduction-caption">
        正式版では書籍の検索・閲覧をはじめリサーチを支援する様々な機能がご利用できます
      </div>
      <img class="fullver-img" :src="fullverIntroduction" alt="Legalscape 正式版について" />
      <v-card-actions>
        <div class="flex-grow-1"></div>
        <v-btn x-large color="primary" href="https://legalscape.jp" target="_blank"
          >正式版の詳細・お問い合わせはこちら</v-btn
        >
        <div class="flex-grow-1"></div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script lang="ts">
import { Component, Prop, Vue } from 'nuxt-property-decorator';
import FULLVER_INTRODUCTION from '@/assets/fullver-introduction.png';

@Component({})
export default class FullverIntroductionDialog extends Vue {
  @Prop()
  show!: boolean;

  readonly fullverIntroduction = FULLVER_INTRODUCTION;
}
</script>
<style lang="scss">
#fullver-introduction-dialog {
  .fullver-img {
    width: 928px;
    display: block;
    margin: 0 auto 32px;
  }

  .readme-title {
    background: #4a5c97;
    color: #fff;
    padding: 40px 0 32px;
    font-size: 34px;
    font-weight: bold;
    text-align: center;
  }

  .fullver-introduction-caption {
    text-align: center;
    font-size: 18px;
    padding: 18px 0 24px;
  }

  .v-card__actions {
    padding-bottom: 40px;
  }
}
</style>
