export default defineComponent({
  name: 'DocumentListItemMetaInfoPdfUri',
  props: {
    pdfUri: {
      type: String,
      required: true
    },
    variant: {
      type: [String, undefined],
      required: false,
      default: undefined
    }
  }
});