<template>
  <!-- 関数型コンポーネントで vuetify のパーツを使う時は nuxt.config.ts の '@nuxtjs/vuetify' の treeShake のオプションを修正すること。 production build で表示されません -->
  <v-icon :title="title" :disabled="disabled">{{ icon }}</v-icon>
</template>

<script lang="ts">
import { DocumentTypeEnum } from 'wklr-backend-sdk/models';
import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class DocumentTypeIcon extends Vue {
  @Prop()
  type!: DocumentTypeEnum | 'html' | 'kommentar' | 'guideline_qa' | 'tsutatsu' | 'tsutatsu_qa';

  @Prop({ default: false })
  disabled!: boolean;

  get title(): string {
    switch (this.type) {
      case DocumentTypeEnum.Book:
        return '書籍';
      case DocumentTypeEnum.Law:
        return '法令等';
      case DocumentTypeEnum.Pubcom:
        return 'パブリックコメント';
      case DocumentTypeEnum.Pdf:
        return '未分類の官公庁資料';
      case DocumentTypeEnum.Extinv:
        return '第三者委員会報告書';
      case 'html':
        return '官公庁資料 (HTML)';
      case DocumentTypeEnum.Guideline:
        return 'ガイドライン';
      case 'guideline_qa':
        return 'ガイドライン Q&A';
      case 'tsutatsu':
        return '通達';
      case 'tsutatsu_qa':
        return '通達 Q&A';
      case DocumentTypeEnum.Case:
        return '判例';
      case 'kommentar':
        return 'コンメンタール';
    }
  }

  get icon(): string {
    switch (this.type) {
      case DocumentTypeEnum.Book:
        return 'mdi-book-open-page-variant';
      case DocumentTypeEnum.Law:
        return 'mdi-text-box';
      case DocumentTypeEnum.Pubcom:
        return 'mdi-web';
      case DocumentTypeEnum.Pdf:
        return 'mdi-pdf-box';
      case DocumentTypeEnum.Extinv:
        return 'mdi-magnify';
      case 'html':
        return 'mdi-web';
      case DocumentTypeEnum.Guideline:
        return 'mdi-script-outline';
      case 'guideline_qa':
        return 'mdi-script-outline';
      case 'tsutatsu':
        return 'mdi-bullhorn';
      case 'tsutatsu_qa':
        return 'mdi-bullhorn';
      case DocumentTypeEnum.Case:
        return 'mdi-gavel';
      case 'kommentar':
        return 'mdi-file-document-edit';
    }
  }
}
</script>
