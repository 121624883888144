import { formatYmd } from '@/utility';
export default defineComponent({
  name: 'DocumentListItemMetaInfoPdfUri',
  props: {
    publishedOn: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    }
  },
  emits: ['filterByLte', 'filterByGte'],
  setup: function setup() {
    return {
      formatYmd: formatYmd
    };
  }
});