import "core-js/modules/es.number.constructor.js";
var qualityIcon = function qualityIcon(quality) {
  if (quality < 1 && 3 < quality) return;
  var q = quality === 1 ? 'c' : quality === 2 ? 'b' : 'a';
  return "mdi-alpha-".concat(q, "-circle");
};
export default defineComponent({
  name: 'DocumentListItemMetaInfoComittieeReport',
  props: {
    stockExchange: {
      type: Boolean,
      required: false,
      default: undefined
    },
    industry: {
      type: String,
      required: false,
      default: undefined
    },
    quality: {
      type: Number,
      required: false,
      default: undefined
    },
    variant: {
      type: [String, undefined],
      required: false,
      default: undefined
    }
  },
  setup: function setup() {
    return {
      qualityIcon: qualityIcon
    };
  }
});