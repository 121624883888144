import { formatYmd } from '@/utility';
export default defineComponent({
  props: {
    officialTitle: {
      type: String,
      required: true
    },
    dateOfEnforcement: {
      type: String,
      required: true
    },
    variant: {
      type: [String, undefined],
      default: undefined
    }
  },
  setup: function setup() {
    return {
      formatYmd: formatYmd
    };
  }
});