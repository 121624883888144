import { Component, Vue, Prop } from 'nuxt-property-decorator';
import DocumentListItemMetaInfoAuthors from '@/components/document-list-item-meta-info-authors.vue';
import DocumentListItemMetaInfoComittieeReport from '@/components/document-list-item-meta-info-comittiee-report.vue';
import DocumentListItemMetaInfoPdfUri from '@/components/document-list-item-meta-info-pdf-uri.vue';
import DocumentListItemMetaInfoPublishedOn from '@/components/document-list-item-meta-info-published-on.vue';
import DocumentListItemMetaInfoPublisher from '@/components/document-list-item-meta-info-publisher.vue';
import DocumentListItemMetaInfoTags from '@/components/document-list-item-meta-info-tags.vue';
import DocumentListItemMetaInfoLaw from '@/components/document-list-item-meta-info-law.vue';
import { DocRecord, DocumentTypeEnum } from 'wklr-backend-sdk/models';

@Component({
  components: {
    DocumentListItemMetaInfoAuthors,
    DocumentListItemMetaInfoComittieeReport,
    DocumentListItemMetaInfoPdfUri,
    DocumentListItemMetaInfoPublishedOn,
    DocumentListItemMetaInfoPublisher,
    DocumentListItemMetaInfoTags,
    DocumentListItemMetaInfoLaw,
  },
})
export default class DocumentListItemMetaInfo extends Vue {
  @Prop()
  document!: DocRecord;

  @Prop()
  variant!: 'small' | 'toc' | 'directory' | undefined;

  @Prop()
  docRecordType!: DocumentTypeEnum;

  @Prop()
  disabled!: boolean;

  @Prop()
  // TEMP: デモ用 = クローリングしてきたPDFはダミー日付が入っているので出したくない
  showPublishedOn!: boolean;

  @Prop()
  pdfUri?: string;

  @Prop()
  authors?: string[];

  @Prop()
  authorsRendered?: HTMLString[];

  @Prop()
  publisher?: string;

  @Prop()
  publisherRendered?: HTMLString;

  @Prop()
  publishedOn?: Date;

  @Prop()
  comittieeReport!: {
    stockExchange?: string;
    industry?: string;
    quality?: number;
  };

  @Prop()
  tags?: string[];

  get isLaw() {
    return this.docRecordType === DocumentTypeEnum.Law;
  }

  filterByAuthorIndex(index: number, event: KeyboardEvent | MouseEvent) {
    this.$emit('filterByAuthorIndex', index, event);
  }

  filterByPublisher(event: KeyboardEvent | MouseEvent) {
    this.$emit('filterByPublisher', event);
  }

  filterByPublishedOnGte(event: KeyboardEvent | MouseEvent) {
    this.$emit('filterByPublishedOnGte', event);
  }

  filterByPublishedOnLte(event: KeyboardEvent | MouseEvent) {
    this.$emit('filterByPublishedOnLte', event);
  }
}
