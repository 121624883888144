import { AccessibleDocRecordTiny, DocRecord, DocRecordTiny, AccessibleDocRecord } from 'wklr-backend-sdk/models';
import { Viewer } from '@/types/Viewer';
import dayjs from 'dayjs';

/**
 * ドキュメントが AccessibleDocRecordTiny か UnaccessibleDocRecordTiny かを判別して型アサーションして返す
 * @param doc DocRecordTiny
 * @returns
 */

export function isAccessible(doc: DocRecordTiny): doc is AccessibleDocRecordTiny;
export function isAccessible(doc: DocRecord): doc is AccessibleDocRecord;

export function isAccessible(doc: DocRecordTiny | DocRecord): doc is AccessibleDocRecordTiny | AccessibleDocRecord {
  return doc.docAccessible === true;
}

export function hasText(doc: AccessibleDocRecordTiny | AccessibleDocRecord): boolean {
  return doc.isWebViewAvailable || isPdfViewAvailable(doc);
}

export function isPdfViewAvailable(doc: AccessibleDocRecordTiny | AccessibleDocRecord): boolean {
  return doc.pdfFileURI !== null && doc.pdfFileURI !== '';
}

export function isReserved(entry: { availableFrom?: Date; purchased?: boolean }): boolean {
  return !!entry.availableFrom && entry.availableFrom.getTime() > new Date().getTime() && !!entry.purchased;
}

export function getAvailableViewers(doc: DocRecordTiny | DocRecord): Viewer[] {
  const viewers: Viewer[] = [];
  if (!isAccessible(doc)) {
    return viewers;
  }
  if (isPdfViewAvailable(doc)) {
    viewers.push('pdf');
  }

  if (doc.isWebViewAvailable) {
    viewers.push('web');
  }

  return viewers;
}

export type AcquisitionType = 'user_purchased' | 'subscription';

export function getAcquisitionType(doc: DocRecord): AcquisitionType {
  return doc.purchased ? 'user_purchased' : 'subscription';
}

export const isAccessibleDocRecord = (record: DocRecord): record is AccessibleDocRecord => {
  return 'docAccessible' in record && record.docAccessible === true;
};

/**
 * 現在の日付が30日後の日付以前であればtrueを返す
 * @param record
 * @returns
 */
export const shouldAppendNewIcon = (collectedAt?: string): boolean => {
  if (collectedAt === undefined) return false;

  // 現在の日付を取得
  const now = dayjs();

  // collectedAtから30日後の日付を計算
  const targetDate = dayjs(collectedAt).add(30, 'day');

  // 現在の日付が30日後の日付以前であればtrueを返す
  return now.isBefore(targetDate);
};
