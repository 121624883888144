export default defineComponent({
  name: 'DocumentListItemMetaInfoPublisher',
  props: {
    publisher: {
      type: String,
      required: true
    },
    disabled: {
      type: Boolean,
      required: true
    },
    publisherRendered: {
      type: String,
      required: true
    }
  },
  emits: ['filter']
});