<template>
  <div
    v-if="label"
    class="document-list-item-viewer-availability"
    :class="{ '-small': variant === 'small' || variant === 'toc' || variant === 'directory' }"
  >
    {{ label }}
  </div>
</template>

<script lang="ts">
import { DocRecord, DocumentTypeEnum } from 'wklr-backend-sdk/models';
import { getAvailableViewers, isAccessible } from '@/utils/documentUtils';
import { Component, Vue, Prop } from 'nuxt-property-decorator';

@Component
export default class DocumentAvailabilityLabel extends Vue {
  @Prop()
  document!: DocRecord;

  @Prop()
  variant!: 'small' | 'toc' | undefined;

  get label(): string | undefined {
    // アクセシブルでない場合は確定できないのでラベルを表示しない
    if (!isAccessible(this.document)) return undefined;

    // メタデータしか持っていない場合はラベルを表示しない
    if (this.document.isMetadataOnly) return undefined;

    // 法律文は常に Web のみなのでラベルを表示しない
    if (this.document.type === DocumentTypeEnum.Law) return undefined;

    const viewers = getAvailableViewers(this.document);
    // 添付のみのなど閲覧できない文献は本文なしとして扱う
    if (viewers.length === 0) return '本文なし';
    if (viewers.length > 1) return undefined;
    switch (viewers[0]) {
      case 'web':
        return 'Webのみ';
      case 'pdf':
        return 'PDFのみ';
    }
  }
}
</script>

<style lang="scss">
.document-list-item-viewer-availability {
  white-space: nowrap;
  display: inline-block;
  margin-left: 6px;
  padding: 1px 6px;
  color: #fff;
  font-size: 12pt;
  background: #1976d2;
  border: none;
  border-radius: 2px;

  &.-small {
    font-size: 10px;
  }
}
</style>
