export default defineComponent({
  name: 'DocumentListItemMetaInfoTags',
  props: {
    disabled: {
      type: Boolean,
      required: true
    },
    tags: {
      type: Array,
      required: true
    }
  }
});