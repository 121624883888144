<template>
  <div class="pa-0 document-list-item-thumbnail">
    <viewer-link
      v-if="!disableDocumentLink"
      :href-document="document"
      target="_blank"
      class="d-inline-block"
      @mouseover="$emit('over')"
      @mouseout="$emit('out')"
    >
      <div class="thumbnail-wrapper" style="position: relative">
        <img
          v-if="shouldAppendNewIcon(document)"
          src="@/assets/new_icon.svg"
          alt="NewDocument"
          style="position: absolute; right: 0; padding-right: 0; top: -5px"
        />
        <img v-lazy="document.thumbnailURI || noImage" />
      </div>
    </viewer-link>
    <img v-else v-lazy="document.thumbnailURI || noImage" class="d-inline-block" />

    <div v-if="isActionable">
      <v-btn outlined small block class="shown-on-hover-entry mt-1 btn-preview" @click="clickPreview">
        <v-icon>mdi-eye</v-icon> プレビュー
      </v-btn>
    </div>
  </div>
</template>

<script lang="ts">
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import ViewerLink from '@/components/viewer-link.vue';
import { DocRecord } from 'wklr-backend-sdk/models';
import NO_IMAGE from '@/assets/noimage.png';
import { decode_object } from 'rison';
import { isAccessibleDocRecord, shouldAppendNewIcon } from '@/utils/documentUtils';

@Component({ components: { ViewerLink } })
export default class DocumentListItemThumbnail extends Vue {
  /** 表示する文書 */
  @Prop() document!: DocRecord;

  /** デザインバリエーション */
  @Prop() size!: 'small' | 'toc' | undefined;
  @Prop() isActionable!: boolean;
  @Prop({ type: Boolean }) disableDocumentLink?: boolean;

  /** for Vue */
  readonly noImage = NO_IMAGE;

  clickPreview() {
    this.$telemetry.sendClickTelemetry(
      {
        button: 'serp-preview_document__preview',
        params: {
          decodedSearchQuery: this.$route.query.q ? decode_object(<string>this.$route.query.q) : undefined,
          docId: this.document.id,
        },
      },
      this.$route,
    );
    this.$emit('preview');
  }
  shouldAppendNewIcon(record: DocRecord) {
    if (isAccessibleDocRecord(record)) {
      return shouldAppendNewIcon(record.collectedAt);
    }
    return false;
  }
}
</script>

<style lang="scss">
.document-list-item-thumbnail {
  width: 100%;
  text-align: center;

  img {
    max-width: 100%;
    box-shadow: 0 0 5px #00000020;
  }
}

// TODO: スタイルはプロパティとして引いてくる。もしくはミニサイズのやつはアクションエリアなくていいかもしれない
.document-list-item.toc {
  .document-list-item-thumbnail img {
    box-shadow: 0 0 3px #00000020;
  }
}

@media screen and (max-width: 800px) {
  .document-list-item-thumbnail {
    padding: 0 30% !important;

    & + * .float-right {
      float: unset !important;
      margin-bottom: 16px;
      text-align: center;
    }
  }
}
</style>
